import { Col, Row } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

import styles from './ListLoader.module.scss'

export default function ListLoader() {
    return (
        <div className={styles.loaderContainer}>
            <Row className="mt-5">
                <Col md="auto">
                    <Spinner className="text-green" animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        </div>
    )
}
