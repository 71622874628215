import { useReducer } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let hbspt: any

export type ContactState = {
    loading?: boolean
    show?: boolean
}

type HotspotFormProps = {
    region: string
    portalId: string
    formId: string
    target: string
    autohide?: boolean
}

const reducer = (currentState: ContactState, newState: ContactState) => {
    return { ...currentState, ...newState }
}

export default function useHotspotForm({ region, portalId, formId, target, autohide }: HotspotFormProps) {
    const initialState = {
        loading: true,
        show: false,
    }

    const [{ loading, show }, setState] = useReducer(reducer, initialState)

    const toggleShow = () => setState({ show: !show })
    const noShow = () => {
        setState({ show: false })
        initHotspotForm()
    }
    const initHotspotForm = () => {
        hbspt.forms.create({
            region,
            portalId,
            formId,
            target,
            onFormReady: () => {
                setState({ loading: false })
            },
            onBeforeFormInit: (ctx: { pageUrl: string }) => {
                if (ctx.pageUrl) {
                    setState({ loading: false })
                }
            },
            onFormSubmitted: function () {
                setState({ show: true })
                if (autohide) {
                    const timerHubspot = setTimeout(() => {
                        noShow()
                    }, 5000)
                    return () => {
                        clearTimeout(timerHubspot)
                    }
                }
            },
        })
    }

    return {
        loading,
        show,
        toggleShow,
        noShow,
        initHotspotForm,
    }
}
