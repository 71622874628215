import { Banner } from 'src/@shared/types/cms/banner'

export type Slide = {
    id: string
    col1: number
    col2: number
    halfTitle?: string
    title: string
    body: string
    imgSrc?: string
    isAnimated: boolean
    backgroundColor?: string
    linkButton: SlideLink
}

export type SlideLink = {
    label: string
    link: string
    variant: string
    target?: string
    hubspotFormId?: string
}

export type BannerSliderProps = {
    customClass?: string
    interval?: number
    slides: Array<Slide>
    id: string
}

export const getSlides = (banners: Banner[]) => {
    return banners.map((banner: Banner) => ({
        id: banner._id,
        col1: banner.thumbnailUrl ? 6 : 8,
        col2: banner.thumbnailUrl ? 6 : 4,
        halfTitle: banner.sectionTitle,
        title: banner.title,
        body: banner.body,
        imgSrc: banner.thumbnailUrl,
        isAnimated: banner.isAnimated,
        linkButton: {
            label: banner.cta,
            link: banner.url,
            hubspotFormId: banner.integrations?.hubspotFormId,
            variant: 'secondary',
            target: banner.url && banner.url.includes('casperlabs.io') ? '_self' : '_blank',
        },
    }))
}
