import { Container, Modal, Row, Toast } from 'react-bootstrap'
import { ReactNode, useLayoutEffect, useState } from 'react'
import Link from 'next/link'

import ListLoader from '@atk/components/loaders/ListLoader/ListLoader'

import useHotspotForm from '@atk/hooks/useHotspotForm'

import styles from './HotspotModal.module.scss'

type HotspotModalProps = {
    formId: string
    target: string
    showModal: boolean
    handleClose: () => void
    children: ReactNode
}

export default function HotspotModal({ formId, target, showModal, handleClose, children }: HotspotModalProps) {
    const { loading, initHotspotForm } = useHotspotForm({
        region: 'na1',
        portalId: '6073987',
        formId,
        target,
    })
    const [showToast, setShowToast] = useState(false)

    useLayoutEffect(() => {
        if (showModal) {
            const timerHubspot = setTimeout(() => {
                initHotspotForm()
            }, 100)
            const timer = setTimeout(() => {
                const el = document.getElementById(target.replace('#', ''))
                if (el && !el.hasChildNodes()) {
                    handleClose()
                    setShowToast(true)
                }
            }, 10000)
            return () => {
                clearTimeout(timerHubspot)
                clearTimeout(timer)
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    return (
        <>
            <Modal centered className="custom-modal" show={showModal} onHide={handleClose} fullscreen={true}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Container className="modal-container">
                        <Row className="justify-content-center">
                            {loading && <ListLoader />}
                            <div className="formContainer">{children}</div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Toast className={styles.toast} onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
                <Toast.Body>
                    <div>
                        <p>Sorry, this form is no longer available.</p>
                        <p>
                            You can request this content through our
                            <Link href="/contact?section=contactForm"> Contact Us</Link> page.
                        </p>
                    </div>
                </Toast.Body>
            </Toast>
        </>
    )
}
