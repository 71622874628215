import Head from 'next/head'
import { useRouter } from 'next/router'
import defaultSplashCard from '@assets/img/default-splash-card.png'
import { useId } from 'react'

const baseUrl = process.env.NEXT_PUBLIC_HOST as string
const siteName = process.env.NEXT_PUBLIC_SITE_NAME as string

export type OpenGraphProps = {
    ogTitle: string
    ogDescription: string
    ogImage?: string
}

export type TwitterMetaProps = {
    card: string
    site: string
    title: string
    description: string
    image: string
    creator: string
    [key: string]: string | number
}

export type BusinessMetaProps = {
    street_address: string
    locality: string
    postal_code: string
    country_name: string
    [key: string]: string | number
}

export type CustomHeadProps = {
    title: string
    description?: string
    og?: OpenGraphProps
    twitter?: TwitterMetaProps
    business?: BusinessMetaProps
    children?: React.ReactNode
}

export default function CustomHead({ title, description = '', og, twitter, business, children }: CustomHeadProps) {
    if (!og) {
        og = {
            ogTitle: title,
            ogDescription: description,
            ogImage: defaultSplashCard.src,
        }
    }

    const router = useRouter()
    const key = useId()
    const ogUrl = `${baseUrl.substring(0, baseUrl.length - 1)}${router.asPath}`
    return (
        // eslint-disable-next-line @next/next/no-script-component-in-head
        <Head>
            <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
            <link rel="icon" type="image/png" sizes="512x512" href="/android-chrome-512x512.png" />
            <link rel="preload" href={`${baseUrl}icons/navbar-toggle-icon.webp`} as="image" />
            <title>{title}</title>
            <meta name="author" content="Casper Labs" />
            <meta name="description" content={description} />
            {/* Indexing / Spiders */}
            <meta name="googlebot" content="all" />
            <meta name="bingbot" content="all" />
            <meta name="robots" content="all" />
            {/* Requested by Client */}
            <meta name="facebook-domain-verification" content="5dpoinx6gwzawxktv7aakmzim1f23c" />
            {/* OpenGraph */}
            <meta property="og:site_name" content={siteName} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:title" content={og.ogTitle} />
            <meta property="og:description" content={og.ogDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={ogUrl} />
            <meta property="og:image" content={og.ogImage} />
            {/* Twitter */}
            {twitter &&
                Object.keys(twitter).map((p: string) => {
                    const content = twitter[p] as string
                    return <meta key={key + p} name={`twitter:${p}`} content={content} />
                })}

            {/* Business Location */}
            {business &&
                Object.keys(business).map((p: string) => {
                    const content = business[p] as string
                    return <meta key={key + p} property={`business:contact_data:${p}`} content={content} />
                })}
            {/* Canonical */}
            <link rel="canonical" href={ogUrl} />
            <link rel="manifest" href="/manifest.json" />
            {children}
        </Head>
    )
}
