import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

export type HeroProps = {
    animation?: 'fadeUp' | 'fadeIn'
    y?: number
    children: React.ReactNode
}

export default function FramerMotion({ children, animation = 'fadeUp', y }: HeroProps) {
    const [isMobile, setIsMobile] = useState(true)

    useEffect(() => {
        setIsMobile(window.innerWidth <= 768)

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (isMobile) return <div>{children}</div>

    const fadeInProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    }
    const fadeUpProps = {
        initial: { opacity: 0, y: (y && y) || 250 },
        whileInView: { opacity: 1, y: 0 },
        transition: { type: 'spring', stiffness: 50, duration: 2 },
        viewport: { once: true },
        className: 'framerMotion',
    }
    const animationProps = animation === 'fadeIn' ? fadeInProps : fadeUpProps
    const { ...motionProps } = animationProps

    return <motion.div {...motionProps}>{children}</motion.div>
}
