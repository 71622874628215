import { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import Image from 'next/image'

import HotspotModal from '@atk/components/layout/Modal/HotspotModal'

import FramerMotion from '@atk/hooks/framerMotion'

import styles from './BannerSlider.module.scss'
import { BannerSliderProps, SlideLink } from './BannerSlider.types'

const CTAButton = ({ linkButton, onClick }: { linkButton: SlideLink; onClick: (value: string) => void }) => (
    <Button
        onClick={() => linkButton.hubspotFormId && onClick(linkButton.hubspotFormId)}
        className="me-lg-3 mb-3 mb-lg-0"
        variant={linkButton.variant}
        href={!linkButton.hubspotFormId ? linkButton.link : undefined}
        target={linkButton.target ?? '_self'}
    >
        {linkButton.label}
    </Button>
)

export default function BannerSlider(props: BannerSliderProps) {
    const { customClass = '', interval = null, slides = [] } = props
    const [showReportModal, setShowReportModal] = useState(false)
    const [hubspotFormId, setHubspotFormId] = useState('')
    const handleCloseReportModal = () => setShowReportModal(false)
    const handleHubspot = (formId: string) => {
        setHubspotFormId(formId)
        setShowReportModal(true)
    }
    const hideArrowClass = slides.length === 1 ? 'bannerSliderHideArrow' : ''
    const componentClass = `bannerSlider ${styles.bannerSlider}  ${customClass} ${hideArrowClass}`
    const slideInterval = slides.filter((x) => x.isAnimated).length > 0 ? interval : null

    return (
        <div className={componentClass} id={props.id}>
            <Carousel indicators={false} interval={slideInterval}>
                {slides.map((slide, index) => (
                    <Carousel.Item
                        className={`${styles.slide} ${
                            (slide.imgSrc && 'bg-gradient-brown') || 'bg-gradient-blue-purple'
                        }`}
                        key={index}
                    >
                        <Container className="d-flex flex-column justify-content-center h-100">
                            <FramerMotion y={50}>
                                <Row className="align-items-center">
                                    {(slide.imgSrc && (
                                        <>
                                            <Col lg={6}>
                                                {slide.halfTitle && <p className="half-title">{slide.halfTitle}</p>}
                                                {slide.title && !slide.body && (
                                                    <p className="mb-4 mb-lg-0 h3">{slide.title}</p>
                                                )}
                                                {slide.body && (
                                                    <div
                                                        className="mb-4 mb-lg-0"
                                                        dangerouslySetInnerHTML={{ __html: slide.body ?? '' }}
                                                    ></div>
                                                )}
                                                {slide.imgSrc && slide.linkButton.label && (
                                                    <div className="mt-4">
                                                        <CTAButton
                                                            linkButton={slide.linkButton}
                                                            onClick={handleHubspot}
                                                        />
                                                    </div>
                                                )}
                                            </Col>
                                            <Col className="d-flex justify-content-end" lg={6}>
                                                <div className={`${styles.imgContainer}`}>
                                                    <Image
                                                        alt="CasperLabs"
                                                        width={800}
                                                        height={800}
                                                        src={slide.imgSrc}
                                                    />
                                                </div>
                                            </Col>
                                        </>
                                    )) || (
                                        <>
                                            <Col md={8} lg={7}>
                                                {slide.halfTitle && <p className="half-title">{slide.halfTitle}</p>}
                                                {slide.title && !slide.body && (
                                                    <p className="mb-4 mb-lg-0 h3">{slide.title}</p>
                                                )}
                                                {slide.body && (
                                                    <div
                                                        className="mb-4 mb-lg-0"
                                                        dangerouslySetInnerHTML={{ __html: slide.body ?? '' }}
                                                    ></div>
                                                )}
                                                {slide.imgSrc && slide.linkButton.label && (
                                                    <CTAButton linkButton={slide.linkButton} onClick={handleHubspot} />
                                                )}
                                            </Col>
                                            <Col className="d-flex justify-content-end" md={4} lg={5}>
                                                <CTAButton linkButton={slide.linkButton} onClick={handleHubspot} />
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </FramerMotion>
                        </Container>
                    </Carousel.Item>
                ))}
            </Carousel>

            <HotspotModal
                formId={hubspotFormId}
                target="#getCTAHubspotForm"
                showModal={showReportModal}
                handleClose={handleCloseReportModal}
            >
                <div id="getCTAHubspotForm"></div>
            </HotspotModal>
        </div>
    )
}
